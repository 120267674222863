import { pickDatavizColor } from '@carbonfact/shared/src/ui/datavizColors';
import * as Plot from '@observablehq/plot';
import { useLayoutEffect, useRef } from 'react';

import { useResize } from '../../hooks/useResize';
import { getXAxis } from './utils/axis/getXAxis';
import { getYAxis } from './utils/axis/getYAxis';
import { plotLegend } from './utils/d3/plotLegend';
import { getForecastPlot } from './utils/data/getForecastPlot';
import { getKnownDataPlot } from './utils/data/getKnownDataPlot';
import { getMaxFootprintFromDataset } from './utils/data/getMaxFootprintFromDataset';

type NetZeroDatasetSettings = {
  key: string;
  text: string;
  color?: string;
};

export type NetZeroChartDataset = {
  data?: {
    points: D3NetZeroObject[];
    dashed?: boolean;
  };
  type: NetZeroChartType;
  settings: NetZeroDatasetSettings;
  forecast?:
    | {
        points: {
          year: number;
          value: number;
        }[];
        dashed?: boolean;
        type: 'pointsForecast';
      }
    | {
        startYear: number;
        startValue: number;
        dashed?: boolean;
        yearlyVariation: number;
        type: 'percentageForecast';
      };
};

export type NetZeroChartProps = {
  datasets: NetZeroChartDataset[];
  plotLegendPosition?: 'top' | 'bottom';
  axisYSettings?: {
    format?: (number: number) => string;
    label?: string;
  };
};

export const NetZeroChart = ({
  datasets,
  axisYSettings,
  plotLegendPosition = 'top',
}: NetZeroChartProps) => {
  const windowSize = useResize();
  const chartRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (!windowSize[0] || !chartRef.current) return;
    const maxFootprint = getMaxFootprintFromDataset(datasets);

    const plots = datasets.map((aDataSet) => {
      const { data, forecast, settings } = aDataSet;

      let forecastStartYear = 0;
      if (forecast) {
        if (forecast.type === 'pointsForecast') {
          forecastStartYear = forecast.points[0].year;
        } else if (forecast.type === 'percentageForecast') {
          forecastStartYear = forecast.startYear;
        }
      }
      const sortedData = data
        ? data.points
            .sort((a, b) => a.year - b.year)
            .map((aDataRow) => ({
              year: aDataRow.year,
              value: aDataRow.value,
              type: settings.key,
              future: aDataRow.year > forecastStartYear,
            }))
        : [];
      return [
        ...getForecastPlot({
          forecast,
          settings,
        }),
        ...getKnownDataPlot({
          data: sortedData,
        }),
      ];
    });

    const legendPoints = datasets.map((aDataset) => aDataset.settings);

    const plot = Plot.plot({
      style: {
        fontSize: '14px',
        fontWeight: '500',
      },
      width: chartRef.current?.offsetWidth,
      x: { type: 'linear' },
      y: {
        type: 'linear',
        grid: true,
        domain: [0, (1 + Y_AXIS_DOMAIN_MARGIN_PCT) * maxFootprint], // give some breathing room to the top of the chart
      },
      color: {
        type: 'categorical',
        domain: legendPoints.map((e) => e.key),
        range: legendPoints.map((e) => e.color ?? pickDatavizColor(e.key)),
      },
      marks: [
        getXAxis(),
        getYAxis(axisYSettings),
        Plot.ruleY([0], { stroke: '#EFF0F0' }),
        ...plots.flat(),
      ],
    });

    const legend = plotLegend({
      plot,
      legendPoints,
    });

    chartRef.current.append(plot);

    if (plotLegendPosition === 'top') {
      if (legend) {
        legend.classList.add('justify-end', '-mb-7');
        chartRef.current.append(legend);
      }
      chartRef.current.append(plot);
    } else if (plotLegendPosition === 'bottom') {
      chartRef.current.append(plot);
      if (legend) chartRef.current.append(legend);
    }

    return () => {
      legend?.remove();
      plot.remove();
    };
  }, [windowSize, axisYSettings, datasets, plotLegendPosition]);

  return (
    <div className="w-full">
      <div ref={chartRef} />
    </div>
  );
};

const Y_AXIS_DOMAIN_MARGIN_PCT = 0.13;

export enum NetZeroChartType {
  TotalFootprint = 'totalFootprint',
  PerUnitFootprint = 'perUnitFootprint',
}

export type D3NetZeroObject = {
  year: number;
  value: number;
  future?: boolean;
};
